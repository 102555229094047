import React, { lazy } from "react";
// import { Redirect } from 'react-router-dom';
const PressureAdd = lazy(() => import("../pages/PressureAdd"));
const PressureEdit = lazy(() => import("../pages/PressureEdit"));
const PressureDetail = lazy(() => import("../pages/PressureDetail"));
export default [
  {
    path: "/",
    component: PressureAdd,
    exact: true,
  },
  {
    path: "/pressureSet",
    component: PressureEdit,
    exact: true,
  },
  {
    path: "/pressureDetail",
    component: PressureDetail,
    exact: true,
  },
];
