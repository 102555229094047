import { upperCase } from "lodash";
import { Toast } from "antd-mobile";
import axios from "axios";
import QueryString from "qs";
import baseUrl from "../config/index";
import * as dsBridge from "dsbridge";
import { md5 } from "./md5";
import { localStorage } from "js-storage";

let pattern = /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/;

// let request_data = {
//   platform: "h5",
// };

const errorhandler = (statusCode, error) => {
  let errorMessage = "";
  switch (statusCode) {
    case 400:
      errorMessage = "请求错误";
      break;
    case 401:
      dsBridge.call("logout", "", () => { });
      errorMessage = "未授权，请登录";
      return;
    case 403:
      errorMessage = "拒绝访问";
      break;
    case 404:
      errorMessage = `请求地址出错`;
      break;
    case 405:
      errorMessage = `不允许的方法`;
      break;
    case 408:
      errorMessage = "请求超时";
      break;
    case 500:
      errorMessage = "服务器内部错误";
      break;
    case 501:
      errorMessage = "服务未实现";
      break;
    case 502:
      errorMessage = "网关错误";
      break;
    case 503:
      errorMessage = "服务不可用";
      break;
    case 504:
      errorMessage = "网关超时";
      break;
    case 505:
      errorMessage = "HTTP版本不受支持";
      break;
    default:
      errorMessage = "未知网络错误";
      break;
  }
  Toast.fail(error || errorMessage, 1);
};

const request = ({ url, method, data }) => {
  let requestData = {};
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      let element = data[key];
      if (typeof element === "string") {
        element = element.trim();
      }
      requestData[key] = element;
    }
  }
  let randomStr = (Math.random() * 900 + 100).toFixed(0);
  let effectTime = new Date().getTime();
  requestData = Object.assign(
    {
      is_app: 1,
      operation: 1000,
      is_select: 1,
    },
    requestData
  );
  let signedData = new Map();
  Object.keys(requestData)
    .sort()
    .forEach((element) => {
      signedData.set(element, requestData[element]);
    });
  function strMapToObj(strMap) {
    let obj = Object.create(null);
    for (let [k, v] of strMap) {
      if (v == undefined || v == null || v.toString().length > 0) {
        obj[k] = v;
      }
    }
    return obj;
  }
  signedData = Object.assign(strMapToObj(signedData), {
    randomStr: randomStr,
    effectTime: effectTime,
    secretKey: "8fY^E1oRdPeDWIGD",
  });
  let sign = md5(window.decodeURIComponent(QueryString.stringify(signedData)));
  let extraParams = {
    sign: sign,
    randomStr: randomStr,
    effectTime: effectTime,
  };
  if (requestData) {
    requestData = Object.assign(extraParams, requestData);
  }
  url = pattern.test(url) ? url : `${baseUrl + url}`;
  return axios({
    method: upperCase(method),
    url: url,
    data: QueryString.stringify(requestData),
    headers: {
      Authorization: localStorage.get("userInfo")
        ? localStorage.get("userInfo").staff_token
        : null,
    },
  })
    .then((res) => {
      if (res.status < 300 && res.status >= 200) {
        if (res.data.status < 300 && res.data.status >= 200) {
          return res.data.data;
        } else {
          errorhandler(res.data.status, res.data.error);
          return null;
        }
      } else {
        errorhandler(res.status, res.error);
        return null;
      }
    })
    .catch((err) => {
      Toast.offline("网络连接错误", 1);
      return null;
    });
};
const cache = new Map();
const cacheRequest = ({ url, method, data }) => {
  let key = `${url}:${method}`;
  if (cache.has(key)) {
    if (cache.get(key).status === "pending") {
      return cache.get(key).wait;
    }
    return Promise.resolve(cache.get(key).data);
  } else {
    // 无缓存，发起真实请求
    let requestApi = request({ url, method, data });
    cache.set(key, { status: "pending", wait: requestApi });
    return requestApi
      .then((res) => {
        // console.log(cache)
        cache.set(key, { status: "success", data: res });
        // console.log(cache)
        cache.delete(key);
        return Promise.resolve(res);
      })
      .catch((err) => {
        cache.set(key, { status: "fail", data: err });
        cache.delete(key);
        Promise.reject(err);
      });
  }
};

export default cacheRequest;
