import * as dsBridge from "dsbridge";

export function setNavTitle(title) {
  dsBridge.call("setNavTitle", title);
}

export function getHeaderAgentInfo() {
  return dsBridge.call("getHeaderAgentInfo");
}

export function getUserInfo() {
  return dsBridge.call("getUserInfo", "getUserInfo");
}

export function getModuleInfo() {
  return dsBridge.call("getModuleInfo");
}

export function openWebH5({ name, data }) {
  return new Promise((resolve, reject) => {
    dsBridge.call("openWebH5", { name, data }, (val) => {
      resolve(val);
    });
  });
}

export function getWebData() {}

export function navBack() {
  dsBridge.call("navBack");
}

export function setNavButtons(arr) {
  new Promise((resolve, reject) => {
    dsBridge.call("setNavButtons", arr, (val) => {
      resolve(val);
    });
  });
}

export function selectDepartment({ group_id }) {
  return new Promise((resolve, reject) => {
    dsBridge.call("selectDepartment", { group_id }, (val) => {
      if (typeof val === "string") {
        val = JSON.parse(val);
      }
      resolve(val);
    });
  });
}

export function openAppPage({ name, data }) {
  dsBridge.call("openAppPage", { name, data });
}

export function scanBarAntiFake(str) {
  dsBridge.call("scanBarAntiFake");
}

export function logcat(str) {
  dsBridge.call("logcat", str);
}

export function callPhone({ name, data }) {
  dsBridge.call("callPhone", { name, data });
}

export function getPressureDetail(data) {
  console.log("getPressureDetail");
  dsBridge.registerAsyn("getPressureDetail", (responseCallback) => {
    responseCallback(JSON.stringify(data));
  });
}

export function sendNotification({ name, data }) {
  dsBridge.call("sendNotification", { name, data });
}

export function notifyH5Refresh(func) {
  dsBridge.register("notifyH5Refresh", func);
}

export function isNotBack(func) {
  dsBridge.register("isNotBack", func);
}
