import * as PressureEditApi from "./service";
import { Toast } from "antd-mobile";
import { localStorage } from "js-storage";
import { getPressureDetail } from "../../utils/hybrid";
import moment from "moment";
export default {
  namespace: "pressureEdit",
  state: {
    formData: {},
    appData: {},
    typeList: [],
    waterList: [],
    fields: {
      area_id: {
        label: "所属部门",
        rules: [
          {
            required: true,
            message: "请填写所属部门",
          },
        ],
      },
      address: {
        label: "验收地址",
        rules: [
          {
            required: true,
            message: "请填写验收地址",
          },
          {
            type: "string",
            max: 1000,
            message: "验收地址字数超过限制",
          },
        ],
      },
      pressure_type: {
        label: "服务类型",
        rules: [
          {
            required: true,
            message: "请选择服务类型",
          },
        ],
      },
      book_time: {
        label: "预约验收时间",
        rules: [
          {
            required: true,
            message: "请选择预约验收时间",
          },
        ],
      },
      house_area_name: {
        label: "小区名称",
        rules: [
          {
            type: "string",
            max: 20,
            message: "小区名称字数超过限制",
          },
        ],
      },
      house_area_no: {
        label: "门牌号",
        rules: [
          {
            type: "string",
            max: 15,
            message: "门牌号字数超过限制",
          },
        ],
      },
      address_type: {
        label: "地址分类",
        rules: [],
      },
      scene_contact: {
        label: "现场联系人",
        rules: [
          {
            type: "string",
            max: 30,
            message: "现场联系人字数超过限制",
          },
        ],
      },
      scene_contact_tel: {
        label: "现场联系人电话",
        rules: [
          { pattern: /^\d{7,8}$|^\d{11}$/, message: "请输入7-8位或11位电话" },
        ],
      },
      declaration_type: {
        label: "现场联系人身份",
        rules: [],
      },
      owner_name: {
        label: "业主姓名",
        rules: [
          {
            type: "string",
            max: 30,
            message: "业主姓名字数超过限制",
          },
        ],
      },
      owner_tel: {
        label: "业主电话",
        rules: [
          { pattern: /^\d{7,8}$|^\d{11}$/, message: "请输入7-8位或11位电话" },
        ],
      },
      is_presence: {
        label: "业主是否在场",
        rules: [
          {
            required: true,
            message: "请选择业主是否在场",
          },
        ],
      },
      distributor_name: {
        label: "经销商名称",
        rules: [],
      },
      distributor_tel: {
        label: "经销商电话",
        rules: [],
      },
      decoration_company: {
        label: "装修公司名称",
        rules: [
          {
            type: "string",
            max: 30,
            message: "装修公司名称字数超过限制",
          },
        ],
      },
      decoration_company_tel: {
        label: "装修公司联系人电话",
        rules: [
          { pattern: /^\d{7,8}$|^\d{11}$/, message: "请输入7-8位或11位电话" },
        ],
      },
      project_manager: {
        label: "项目经理姓名",
        rules: [
          {
            type: "string",
            max: 10,
            message: "项目经理姓名字数超过限制",
          },
        ],
      },
      project_manager_tel: {
        label: "项目经理电话",
        rules: [
          { pattern: /^\d{7,8}$|^\d{11}$/, message: "请输入7-8位或11位电话" },
        ],
      },
      project_manager_score: {
        label: "项目经理积分",
        rules: [
          {
            type: "number",
            transform: (value) => {
              return Number(value);
            },
            max: 999999999,
            message: "项目经理积分字数超过限制",
          },
        ],
      },
      hydraulic_name: {
        label: "工人姓名",
        rules: [
          {
            type: "string",
            max: 10,
            message: "工人姓名字数超过限制",
          },
        ],
      },
      hydraulic_tel: {
        label: "工人电话",
        rules: [
          { pattern: /^\d{7,8}$|^\d{11}$/, message: "请输入7-8位或11位电话" },
        ],
      },
      hydraulic_score: {
        label: "工人积分",
        rules: [
          {
            type: "number",
            transform: (value) => {
              return Number(value);
            },
            max: 999999999,
            message: "工人积分字数超过限制",
          },
        ],
      },
      member_card: {
        label: "积分卡卡号",
        rules: [
          {
            type: "string",
            max: 20,
            message: "积分卡卡号字数超过限制",
          },
        ],
      },
      kitchen: {
        label: "厨",
        rules: [],
      },
      toilet: {
        label: "卫",
        rules: [],
      },
      balcony: {
        label: "阳台",
        rules: [],
      },
      pipe_type: {
        label: "水管类型",
        rules: [],
      },
      water_type: {
        label: "水管型号",
        rules: [
          {
            type: "string",
            max: 10,
            message: "水管型号字数超过限制",
          },
        ],
      },
      serve_type: {
        label: "试压类型",
        rules: [
          {
            required: true,
            message: "请选择试压类型",
          },
        ],
      },
      tds: {
        label: "TDS值",
        rules: [
          {
            type: "number",
            transform: (value) => {
              return Number(value);
            },
            max: 99999,
            message: "TDS值字数超过限制",
          },
        ],
      },
      pipe_trend: {
        label: "管道走向",
        rules: [],
      },
      install_type: {
        label: "安装方式",
        rules: [
          {
            required: true,
            message: "请选择安装方式",
          },
        ],
      },
      pipe_brand: {
        label: "使用水管品牌（地暖专用）",
        rules: [
          {
            type: "string",
            max: 20,
            message: "使用水管品牌（地暖专用）字数超过限制",
          },
        ],
      },
      spool_type: {
        label: "使用线管品牌",
        rules: [
          {
            required: true,
            message: "请填写使用线管品牌",
          },
        ],
      },
      use_pipe: {
        label: "使用管道品牌",
        rules: [
          {
            required: true,
            message: "请填写使用管道品牌",
          },
        ],
      },
      door_packet: {
        label: "门套安装情况",
        rules: [],
      },
      is_care_water: {
        label: "朴乐防水",
        rules: [],
      },
      plumber_id: {
        label: "验收人员姓名",
        rules: [
          {
            required: true,
            message: "请选择验收人员姓名",
          },
        ],
      },
      plumber_tel: {
        label: "验收人员电话",
        rules: [
          {
            required: true,
            message: "请填写验收人员电话",
          },
        ],
      },
      special_event: {
        label: "异常情况",
        rules: [],
      },
      leakage_reason: {
        label: "漏水说明",
        rules: [
          {
            type: "string",
            max: 300,
            message: "漏水说明字数超过限制",
          },
        ],
      },
      desc: {
        label: "其他情况备注",
        rules: [
          {
            type: "string",
            max: 300,
            message: "其他情况备注字数超过限制",
          },
        ],
      },
      pressure_tool: {
        label: "试压工具",
        rules: [],
      },
      pressure_time: {
        label: "试压开始时间",
        rules: [],
      },
      close_start_time: {
        label: "闭水开始时间",
        rules: [],
      },
      pressure_complete_time: {
        label: "试压结束时间",
        rules: [],
      },
      close_end_time: {
        label: "闭水结束时间",
        rules: [],
      },
      pressure_pressure: {
        label: "试压压力(KG)",
        rules: [],
      },
      start_pressure: {
        label: "开始压力值",
        rules: [
          {
            type: "string",
            max: 10,
            message: "开始压力值字数超过限制",
          },
        ],
      },
      last_pressure: {
        label: "试压结束压力值",
        rules: [
          {
            type: "string",
            max: 10,
            message: "结束压力值字数超过限制",
          },
        ],
      },
      pressure_drop: {
        label: "压降值",
        rules: [
          {
            type: "string",
            max: 20,
            message: "压降值字数超过限制",
          },
        ],
      },
      press_time: {
        label: "试压时间(分钟)",
        rules: [],
      },
      close_press_time: {
        label: "闭水时间(小时)",
        rules: [],
      },
      is_qualified: {
        label: "验收是否合格",
        rules: [],
      },
      authentic: {
        label: "产品真伪",
        rules: [],
      },
      vr_link: {
        label: "VR链接",
        rules: [
          {
            pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/,
            message: "请正确的链接地址",
          },
        ],
      },
      assurance_card: {
        label: "质保卡类型",
        rules: [],
      },
      card_type: {
        label: "质保类型",
        rules: [],
      },
      quality_card: {
        label: "质保卡卡号",
        rules: [
          {
            type: "string",
            max: 60,
            message: "质保卡卡号字数超过限制",
          },
        ],
      },
      wall_model: {
        label: "墙面材料型号",
        rules: [
          {
            type: "string",
            max: 60,
            message: "墙面材料型号字数超过限制",
          },
        ],
      },
      floor_model: {
        label: "地面材料型号",
        rules: [
          {
            type: "string",
            max: 60,
            message: "地面材料型号字数超过限制",
          },
        ],
      },
      is_wall_deal: {
        label: "墙角是否倒角处理",
        rules: [],
      },
      is_pin_deal: {
        label: "管根是否倒角处理",
        rules: [],
      },
      is_node_deal: {
        label: "节点是否进行加强处理",
        rules: [],
      },
      is_door_alert: {
        label: "门口有无警戒胶带",
        rules: [],
      },
      is_put_up: {
        label: "验证提示是否张贴",
        rules: [],
      },
      roof_state: {
        label: "楼下业主房顶情况",
        rules: [
          {
            required: true,
            message: "楼下业主房顶情况必填",
          },
          {
            type: "string",
            max: 500,
            message: "楼下业主房顶情况字数超过限制",
          },
        ],
      },
      keep_year: {
        label: "质保年限",
        rules: [
          {
            required: true,
            message: "质保年限必填",
          },
          {
            type: "string",
            max: 20,
            message: "质保年限字数超过限制",
          },
        ],
      },
    },
  },
  effects: {
    *getTypeSetList(_, { call, put }) {
      const res = yield call(PressureEditApi.getTypeSetList, {});
      if (res) {
        return yield put({
          type: "save",
          payload: {
            typeList: res.map((item) => {
              return {
                ...item,
                label: item.set_name,
                value: item.set_name,
              };
            }),
          },
        });
      }
    },
    *getWaterList(_, { call, put }) {
      const res = yield call(PressureEditApi.getWaterList, {
        module_id: localStorage.get("moduleInfo").menu_id,
      });
      if (res) {
        yield put({
          type: "save",
          payload: {
            waterList: res.map((item) => {
              return {
                ...item,
                label: item.staff_name,
                value: item.staff_id,
              };
            }),
          },
        });
        return res.map((item) => {
          return {
            ...item,
            label: item.staff_name,
            value: item.staff_id,
          };
        });
      }
    },
    *insertOrUpdateTestPressure(_, { call, put, select }) {
      const { payload, succEvent } = _;
      const { formData } = yield select(({ pressureEdit }) => pressureEdit);
      console.log(formData);
      const res = yield call(PressureEditApi.insertOrUpdateTestPressure, {
        module_id: localStorage.get("moduleInfo").menu_id,
        ...payload,
        area_id: formData.area_id,
      });
      if (res) {
        if (succEvent) {
          succEvent();
        }
        Toast.success(res, 1);
      }
    },
    *getTestPressureDaily(_, { call, put }) {
      const { payload } = _;
      const res = yield call(PressureEditApi.getTestPressureDaily, {
        ...payload,
        module_id: localStorage.get("moduleInfo").menu_id,
      });
      if (res) {
        console.log(res);
        getPressureDetail(res);
        return yield put({
          type: "save",
          payload: {
            formData: {
              ...res,
              address_type: res.address_type ? [res.address_type] : "",
              pressure_type: res.pressure_type ? [res.pressure_type] : "",
              declaration_type: res.declaration_type
                ? [res.declaration_type]
                : "",
              is_presence: res.is_presence ? [res.is_presence] : "",
              kitchen: res.kitchen ? [res.kitchen] : "",
              toilet: res.toilet ? [res.toilet] : "",
              balcony: res.balcony ? [res.balcony] : "",
              pipe_type: res.pipe_type ? [res.pipe_type] : "",
              serve_type: res.serve_type ? [res.serve_type] : "",
              pipe_trend: res.pipe_trend ? [res.pipe_trend] : "",
              install_type: res.install_type ? [res.install_type] : "",
              spool_type: res.spool_type ? [res.spool_type] : "",
              use_pipe: res.use_pipe ? [res.use_pipe] : "",
              door_packet: res.door_packet ? [res.door_packet] : "",
              is_care_water: res.is_care_water ? [res.is_care_water] : "",
              plumber_id: res.plumber_id ? [res.plumber_id] : "",
              special_event: res.special_event ? [res.special_event] : "",
              pressure_tool: res.pressure_tool ? [res.pressure_tool] : "",
              is_qualified: res.is_qualified ? [res.is_qualified] : "",
              authentic: res.authentic ? [res.authentic] : "",
              card_type: res.card_type ? [res.card_type] : "",

              is_wall_deal: res.is_wall_deal ? [res.is_wall_deal] : "",
              is_pin_deal: res.is_pin_deal ? [res.is_pin_deal] : "",
              is_node_deal: res.is_node_deal ? [res.is_node_deal] : "",
              is_door_alert: res.is_door_alert ? [res.is_door_alert] : "",
              is_put_up: res.is_put_up ? [res.is_put_up] : "",

              assurance_card: res.assurance_card ? [res.assurance_card] : "",
              book_time: res.book_time
                ? new Date(moment(res.book_time).valueOf())
                : "",
              edit_time: res.edit_time
                ? new Date(moment(res.edit_time).valueOf())
                : "",
              pressure_time: res.pressure_time
                ? new Date(moment(res.pressure_time).valueOf())
                : "",
              pressure_complete_time: res.pressure_complete_time
                ? new Date(moment(res.pressure_complete_time).valueOf())
                : "",
              close_start_time: res.close_start_time
                ? new Date(moment(res.close_start_time).valueOf())
                : "",
              close_end_time: res.close_end_time
                ? new Date(moment(res.close_end_time).valueOf())
                : "",
            },
            appData: {
              ...res,
            },
          },
        });
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
