import Request from "../../utils/request";

export async function getTypeSetList(params) {
  return Request({
    url: "typeController/getTypeSetList",
    method: "POST",
    data: { ...params },
  });
}

export async function getWaterList(params) {
  return Request({
    url: "staffController/getWaterList",
    method: "POST",
    data: { ...params },
  });
}

export async function insertOrUpdateTestPressure(params) {
  return Request({
    url: "pressureController/insertOrUpdateTestPressure",
    method: "POST",
    data: { ...params },
  });
}

export async function getMemoryList(params) {
  return Request({
    url: 'pressureController/getMemoryList',
    method: 'post',
    data: { ...params },
  });
}
