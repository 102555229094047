import Request from "../../utils/request";

export async function getTestPressureDaily(params) {
  return Request({
    url: "pressureController/getTestPressureDaily",
    method: "POST",
    data: { ...params },
  });
}

export async function auditTestPressure(params) {
  return Request({
    url: "pressureController/auditTestPressure",
    method: "POST",
    data: { ...params },
  });
}

export async function deleteTestPressure(params) {
  return Request({
    url: "pressureController/deleteTestPressure",
    method: "POST",
    data: { ...params },
  });
}

export async function getTypeSetList(params) {
  return Request({
    url: "typeController/getTypeSetList",
    method: "POST",
    data: { ...params },
  });
}

export async function getWaterList(params) {
  return Request({
    url: "staffController/getWaterList",
    method: "POST",
    data: { ...params },
  });
}


export async function insertOrUpdateTestPressure(params) {
  return Request({
    url: "pressureController/insertOrUpdateTestPressure",
    method: "POST",
    data: { ...params },
  });
}

export async function getMemoryList(params) {
  return Request({
    url: "pressureController/getMemoryList",
    method: "post",
    data: { ...params },
  });
}