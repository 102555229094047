/* eslint-disable import/no-mutable-exports */
let baseUrl;
if (process.env.NODE_ENV === "development") {
  //  baseUrl = "http://pressuretest.akan.com.cn:81/api/";
  // baseUrl = "https://pressure-hd.akan.com.cn/api/";
  baseUrl = "https://pa.akan.com.cn/api/";
} else {
  // baseUrl = window.location.origin + "/";
  baseUrl = "https://pa.akan.com.cn/api/";
  // baseUrl = "https://pressure-hd.akan.com.cn/api/";
}
export default baseUrl;