import * as PressureAddApi from "./service";
import { localStorage } from "js-storage";
import { Toast } from "antd-mobile";

export default {
  namespace: "pressureAdd",
  state: {
    formData: {},
    typeList: [],
    waterList: [],
    fields: {
      area: {
        label: "所属部门",
        rules: [
          {
            required: true,
            message: "请选择部门",
          },
        ],
      },
      address: {
        label: "验收地址",
        rules: [
          {
            required: true,
            message: "请填写验收地址",
          },
          {
            type: "string",
            max: 1000,
            message: "试压地址字数超过限制",
          },
        ],
      },
      book_time: {
        label: "预约验收时间",
        rules: [
          {
            required: true,
            message: "请选择日期",
          },
        ],
      },
      pressure_type: {
        label: "服务类型",
        rules: [
          {
            required: true,
            message: "请选择服务类型",
          },
        ],
      },
      serve_type: {
        label: "试压类型",
        rules: [
          {
            required: true,
            message: "请选择试压类型",
          },
        ],
      },
      install_type: {
        label: "安装方式",
        rules: [
          {
            required: true,
            message: "请选择安装方式",
          },
        ],
      },
      plumber_id: {
        label: "验收人员姓名",
        rules: [
          {
            required: true,
            message: "请选择验收人员姓名",
          },
        ],
      },
      plumber_tel: {
        label: "验收人员电话",
        rules: [
          {
            required: true,
            message: "请选择验收人员",
          },
        ],
      },
      house_area_no: {
        label: "门牌号",
        rules: [
          {
            type: "string",
            max: 15,
            message: "门牌号字数超过限制",
          },
        ],
      },
      scene_contact: {
        label: "现场联系人",
        rules: [
          {
            type: "string",
            max: 30,
            message: "现场联系人字数超过限制",
          },
        ],
      },
      scene_contact_tel: {
        label: "现场联系人电话",
        rules: [
          {
            required: false,
            pattern: /^\d{7,8}$|^\d{11}$/,
            message: "现场联系人电话请输入7-8位或11位电话",
          },
        ],
      },
      house_area_name: {
        label: "小区名称",
        rules: [
          {
            type: "string",
            max: 20,
            message: "小区名称字数超过限制",
          },
        ],
      },
      address_type: {
        label: "地址分类",
        rules: [],
      },
      kitchen: {
        label: "厨",
        rules: [],
      },
      toilet: {
        label: "卫",
        rules: [],
      },
      balcony: {
        label: "阳台",
        rules: [],
      },
      pipe_type: {
        label: "水管类型",
        rules: [],
      },
      spool_type: {
        label: "使用线管品牌",
        rules: [
          {
            required: true,
            message: "请填写使用线管品牌",
          },
        ],
      },
      use_pipe: {
        label: "使用管道品牌",
        rules: [
          {
            required: true,
            message: "请填写使用管道品牌",
          },
        ],
      },
      assurance_card: {
        label: "质保卡类型",
        rules: [],
      },
      authentic: {
        label: "产品真伪",
        rules: [],
      },
    },
  },
  effects: {
    *getTypeSetList(_, { call, put }) {
      const res = yield call(PressureAddApi.getTypeSetList, {});
      if (res) {
        return yield put({
          type: "save",
          payload: {
            typeList: res.map((item) => {
              Object.assign(item, {
                label: item.set_name,
                value: item.set_name,
              });
              return item;
            }),
          },
        });
      }
    },
    *getWaterList(_, { call, put }) {
      const res = yield call(PressureAddApi.getWaterList, {
        module_id: localStorage.get("moduleInfo").menu_id,
      });
      if (res) {
        return yield put({
          type: "save",
          payload: {
            waterList: res.map((item) => {
              Object.assign(item, {
                label: item.staff_name,
                value: item.staff_id,
              });
              return item;
            }),
          },
        });
      }
    },
    *insertOrUpdateTestPressure(_, { call, put }) {
      const { payload, succEvent } = _;
      const res = yield call(PressureAddApi.insertOrUpdateTestPressure, {
        module_id: localStorage.get("moduleInfo").menu_id,
        ...payload,
      });
      if (res) {
        Toast.success('操作成功', 1);
        if (succEvent) {
          succEvent();
        }
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
