// import * as PopupApi from './service';

export default {
  namespace: 'popup',
  state: {

  },
  effects: {
    // * effectsDemo(_, { call, put }) {
    //   const { status, data } = yield call(PopupApi.demo, {});
    //   if (status == '200') {
    //     yield put({ type: 'save',
    //       payload: {
    //         topData: data,
    //       } });
    //   }
    // },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
